import React, { useState, useEffect, useRef } from 'react';
import { MirList } from 'shared/settings/apiconfig';
import { getSellerHeaders, getSellerUrl } from 'shared/http';
import Datatable from 'shared/datatable';
import { useHistory } from 'react-router-dom'; 

const Mir = ({ filter, mirstatus, saleid, state,props }) => {
    const history = useHistory();
    const initialSearchState = {
        saleid:  { field: "saleid", value: saleid, op: "=", join: "AND"} ,
        type: { field: "type",value: 'RA', op: "=",  join: "AND"}
    };

    const datatable = useRef(null);

    const [search, setSearch] = useState(initialSearchState);
    const [updateSearch, setUpdateSerach] = useState(false);
    const [navigateToMir, setNavigateToMir] = useState(null);
    const [navigateTo, setNavigateTo] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [internalState, setInternalState] = useState(state); 

    const customMirInvoiceView = (value) => {

        return (
            <span >
                {value['invoicenumber'] ? value['invoicenumber'] : '- - -'}
            </span>
        );

    }
    
    const customInvoiceView = (values) => {
        let irmvalue = values.itemreceiptmethod ? JSON.parse(values.itemreceiptmethod) : ''
        return (<span style={{ color: irmvalue !== "pickup" && "blue", cursor: irmvalue === "pickup" ? "auto" : "pointer",}}  onClick={() => {
            if (irmvalue === "shipping") {
                invoiceview(values.parentinvoiceid);
            }
        }}>{values['parentInvoiceNumber'] ? values['parentInvoiceNumber'] : '- - -'}</span>);
    }
    const [config, setConfig] = useState({
        url: MirList,
        params: {},
        columns: [
            { name: 'Item Title', property: 'itemtitle', sort: false, width: '250px'},
            { name: 'Invoice Number', property: 'invoicenumber', sort: false, width: '130px', columnType: 'custom', customColumn: customMirInvoiceView  },
            // { name: 'Parent Invoice', property: 'parentInvoiceNumber', sort: false, width: '130px', columnType: 'custom', customColumn: customInvoiceView },
            { name: 'Email', property: 'buyeremail', sort: false, width: '130px' },
            { name: 'SKU', property: 'SKU', sort: false, width: '130px' },
            { name: 'Contract ID', property: 'contractid', sort: false, width: '130px' },
            { name: 'Contract Status', property: 'contractstatus', sort: false, width: '130px' },
            { name: 'Claim ID', property: 'claimid', sort: false, width: '130px' },
            { name: 'Claim Status', property: 'claimstatus', sort: false, width: '150px' },
        ],
        pagination: true,
        field: ["id","orderid","contractid","contractstatus","claimid","claimstatus","itemtitle","SKU","category","invoicenumber","buyeremail","type","parentinvoiceid","invoiceid"],   
        ignoreRowBackground: true
    });

    useEffect(() => {
        const mergedFilter = { ...initialSearchState, ...filter };
        setSearch(mergedFilter);
        setUpdateSerach(true)
    }, [filter]);

    useEffect(() => {
        if (updateSearch || mirstatus) { 
        if (datatable.current) {
            datatable.current.searchData(props?.location?.state?.datatable?.next ? 1 : null);
            setUpdateSerach(false)
        }
    }

    }, [search]);

    useEffect(() => {
        if (navigateTo) {
            const stateValue = {
                ...state,
               
            };
            history.push({ 
                pathname: `/invoice/view/${navigateTo}`, 
                state: { 
                    mirstatus: true,  
                    prevPath: history?.location?.pathname, 
                    state: stateValue,
                    datatable: tableData.stateData,
                } 
            });    
            setNavigateTo(null);
        }
    }, [internalState, navigateTo]);

    useEffect(() => {
        if (navigateToMir) {
            history.push({
                pathname: `/invoice/ra/view/${navigateToMir}`,
                state: {
                    mirstatus: true,
                    prevPath: history?.location?.pathname,
                    state: state,
                    datatable: tableData.stateData,
                }
            });
            setNavigateToMir(null);
        }
    }, [navigateToMir]);

    
    const invoiceview = (parentinvoiceid) => {
        setNavigateTo(parentinvoiceid);
    };
    const invoiceMirview = (invoiceid) => {
        setNavigateToMir(invoiceid);
    };
   
   const  updateTableData = (event) => {
    setTableData(event)
   }

    return (
        <div className="" style={{ width: '100%' }}>
            <Datatable  {...props} config={{ ...config }} search={{ search: { ...search }, searchData:updateSearch ? true : false }} ref={datatable} onDataUpdate={updateTableData}  ></Datatable>
        </div>
    );
};

export default Mir;
