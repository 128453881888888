const AREA = [
    'Barn',
    'Bedroom',
    'Entertainment Cave',
    'Garage',
    'Kids Playroom',
    'Kitchen & Dining',
    'Laundry Storage',
    'Living Room',
    'Office',
    'Patio & Lawn'
];

export default AREA;
