// library imports
import { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// project imports
import { FeaturesRouter } from './features/routers/FeaturesRouter';
import AutoReload from './features/components/auto-reload/AutoReload.jsx';

// style imports
import './App.scss';

export class App extends Component {

    componentDidMount() {
        // this.clearCache();
        this.clearErrors();
    }

    // Every Three Seconds console will be cleared
    clearErrors = () => {
        console?.clear()
        setInterval(() => {
            console?.clear()
        }, 3000);
    }

    clearCache = () => {
        try {
            caches.keys().then((cacheNames) => {
                return Promise.all(
                    cacheNames.filter((cacheName) => {
                        // Return true if you want to remove this cache,
                        // but remember that caches are shared across
                        // the whole origin
                    }).map((cacheName) => {
                        return caches.delete(cacheName);
                    })
                );
            })
        } catch (error) {
        }
    }

    render() {
        return (
            <div data-testid="app-comp" className="w-100 h-100">
                <Router>
                    <Route component={FeaturesRouter} />
                </Router>
                <AutoReload url="/index.html" forceReload/>
            </div>
        );
    }
}
