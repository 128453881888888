import { Quill } from 'react-quill';
import MagicUrl from "quill-magic-url";

Quill.register("modules/magicUrl", MagicUrl);
const SnowTheme = Quill.import("themes/snow");
const Delta = Quill.import("delta");

export class ExtendSnowTheme extends SnowTheme {
    constructor(quill, options) {
        super(quill, options);
        // flag that ensure only create/add our custom elements once
        this.tooltipModified = false;
        // listener for adding our custom input for link 'text'
        quill.on("selection-change", (range) => {
            if (this.tooltipModified) return;
            // mark flag
            this.tooltipModified = true;
            let tooltip = quill.theme.tooltip;
            let index; // link start index
            let length; // link text length
            let linkValue; // link href value

            // custom save link text funtion
            const save = () => {
                let text = "";
                let finalLink = "";
                let idx = index ? index : tooltip.quill.selection.lastRange.index;
                let len = length ? length : tooltip.quill.selection.lastRange.length;
                if (idx >= 0 && len >= 0) {
                    text = quill.getText(idx, len);
                }
                if (typeof linkValue === 'string' && linkValue.toLowerCase().startsWith("www")) {
                    finalLink = "https://" + linkValue;
                } else if(typeof linkValue === 'string' && (!(linkValue.toLowerCase().startsWith("www")) && !(linkValue.toLowerCase().startsWith("https")) && !(linkValue.toLowerCase().startsWith("http")))) {
                    finalLink = "https://www." + linkValue;
                } else {
                    finalLink = linkValue;
                }
                let delta = new Delta()
                    .retain(index ? index : tooltip.quill.selection.lastRange.index)
                    .delete(length ? length : tooltip.quill.selection.lastRange.length)
                    .insert(text ? text : linkValue, { link: finalLink })
                quill.updateContents(delta);

                index = null;
                length = null;
            };

            const linkInputLabel = document.createElement("span");
            linkInputLabel.textContent = "Enter link: ";
            tooltip.root.insertBefore(linkInputLabel, tooltip.textbox);
            var placeholder = quill.theme.tooltip;
            var input = placeholder.root.querySelector("input[data-link]");
            input.dataset.link = 'Enter the link';

            // Modify original link textbox
            const textbox = tooltip.textbox;
            const setLinkValue = (e) => {
                linkValue = e.target.value;
            };

            const setLinkValuewWhenFocused = (e) => {
                const range = getSelection();
                if (range) {
                    if (range.type === 'Range') {
                        e.target.value = "";
                    } 
                }
            }

            // quill auto focus this textbox by default, so this ensure we get the value
            textbox.addEventListener("focus", setLinkValuewWhenFocused);
            // if user update link, need to update value
            textbox.addEventListener("input", setLinkValue);

            textbox.addEventListener("keydown", (e) => {
                if (e.key === "Enter") {
                    save();
                    tooltip.root.classList.add("ql-hidden");
                }
            });

            // Hack 'ql-action' button
            const actionBtn = tooltip.root.querySelector(".ql-action");
            actionBtn.addEventListener("click", () => {
                if (tooltip.root.classList.contains("ql-hidden")) {
                    save();
                    return;
                }
                if (tooltip.root.classList.contains("ql-editing")) {
                    index = tooltip.linkRange.index;
                    length = tooltip.linkRange.length;
                }
            });
        });
    }
}

Quill.register("themes/snow", ExtendSnowTheme);
