// Method to capitalize first letter
export const capitalizeFirstLetter = (content) => {
    try {
        if(content)
        return content.charAt(0).toUpperCase() + content.slice(1);
        else
        return ''
    }catch(e) {
        return ''
    }
}