import React, { useState, useEffect, useRef } from 'react';
import { fullFillmentList } from 'shared/settings/apiconfig';
import { useHistory } from 'react-router-dom';
import Datatable from 'shared/datatable';
import { capitalizeFirstLetter } from 'shared/utils';

const FullfillmentList = ({ filter, fullfillmentStatus, saleid, state }) => {
    const history = useHistory();
    const initialSearchState = {
        saleid: { field: "saleid", value: saleid, op: "=", join: "AND" }
    };

    const datatable = useRef(null);
    const customirm = (value) => {
        const irmObject = JSON.parse(value.irm);
        if (typeof irmObject === 'object' && irmObject !== null) {
            const irm = Object.keys(irmObject).filter(key => irmObject[key] === true);
            return capitalizeFirstLetter(irm[0]) ;
        }
    }
    const customInvoiceNumber = (values) => {
        return (<span style={{ color: "blue", cursor: "pointer" }} onClick={() => invoiceview(values.invoiceid)}>{values['invoicenumber'] ? values['invoicenumber'] : '- - -'}</span>);
    }
    const invoiceview = (invid) => {
        history.push({ pathname: `/invoice/view/${invid}`, state: { name: "Saledashboard", prevPath: `/sales/dashboard/${saleid}`,state } })
    }
    const [search, setSearch] = useState({ ...initialSearchState, ...filter });

    useEffect(() => {
        const mergedFilter = { ...initialSearchState, ...filter };
        setSearch(mergedFilter);
    }, [filter]);

    useEffect(() => {
        if (fullfillmentStatus) {
            if (datatable.current) {
                datatable.current.searchData();
            }
        }

    }, [search]);

    const config = {
        name: 'fullfillment',
        url: fullFillmentList,
        params: {},
        columns: [
           
            { name: 'Item Title', property: 'itemtitle', sort: false, width: '200px' },
            { name: 'Invoice Number', property: 'invoicenumber', sort: false, width: '150px',columnType: 'custom', customColumn: customInvoiceNumber },
            { name: 'Email', property: 'buyeremail', sort: false, width: '150px' },
            { name: 'Tracking Number', property: 'trackingnumber', sort: false, width: '150px' },
            { name: 'Carrier', property: 'carrier', sort: false, width: '150px' },
            { name: 'Item Receipt Method', property: 'irm', sort: false, width: '150px', columnType: 'custom', customColumn: customirm }
        ],
        field: ["id", "itemid","carrier","trackingnumber", "itemtitle", "irm","invoicenumber","invoiceid","buyeremail"],
        sort: [{ field: 'id', direction: 'desc' }],
        pagination: true,
        ignoreRowBackground: true
    };

   

    return (
        <div className="" style={{ width: '100%' }}>
            <Datatable config={{ ...config }} search={{ search: { ...search }, searchData: fullfillmentStatus ?  false : true }} ref={datatable} ></Datatable>
        </div>
    );
};

export default FullfillmentList;
