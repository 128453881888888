import React, { Component } from "react";
import PropTypes from 'prop-types'
import { Modal, Button } from "react-bootstrap";
import { autoReloadMesssages as messages } from '../../config/systemmessages'

/**
  * Auto Reload on app deployed
  *
  */
class AutoReload extends Component {
    constructor(props) {
        super(props);
        this.previousHash = null;
        this.state = {
            codeHasChanged: false,
        };
        this.fetchSource = this.fetchSource.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    componentDidMount() {
        this.handleTabChange();
        document.addEventListener('visibilitychange', this.handleTabChange);
    }

    handleTabChange() {
        if (document.hidden) {
            clearInterval(this.interval);
        } else {
            this.fetchSource();
            const { tryDelay } = this.props;
            this.interval = setInterval(this.fetchSource, tryDelay);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        document.removeEventListener('visibilitychange', this.handleTabChange);
    }

    fetchSource() {
        var headers = new Headers();
        headers.append('pragma', 'no-cache');
        headers.append('cache-control', 'no-cache');

        var requestInit = {
            method: 'GET',
            headers,
        };

        return fetch(this.props.url, requestInit)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error("offline");
                }
                return response.text();
            })
            .then(html => {
                const hash = this.hash(html);
                if (!this.previousHash) {
                    this.previousHash = hash;
                    return;
                }
                if (this.previousHash !== hash) {
                    this.previousHash = hash;
                    this.setState({ codeHasChanged: true });
                    clearInterval(this.interval);
                    if (this.props.forceReload) {
                        this.reloadApp();
                    }
                }
            })
            .catch(() => {
                /* TO-DO Bugsnag */
            });
    }

    /**
     * HashCode function for strings
     *
     */
    hash(str) {
        const len = str.length;
        let hash = 0;
        if (len === 0) return hash;
        let i;
        for (i = 0; i < len; i++) {
            hash = (hash << 5) - hash + str.charCodeAt(i);
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    reloadApp(event) {
        // window.location.href = "/"; // reload page to home
        window.location.reload(true); // reload current page
        event?.preventDefault();
    }

    render() {
        return (
            <Modal centered
                animation={false}
                backdrop="static"
                show={!this.props.forceReload && !this.props.sessionModal && this.state.codeHasChanged}

            >
                <Modal.Body >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        {messages.newVersionText}
                        <div className="d-flex flex-row mt-4">
                            <Button variant="secondary mx-2" onClick={this.reloadApp}>
                                {messages.clickToRefreshText}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

AutoReload.propTypes = {
    url: PropTypes.string.isRequired,
    tryDelay: PropTypes.number.isRequired,
    forceReload: PropTypes.bool,
};

AutoReload.defaultProps = {
    url: "/",
    tryDelay: 10 * 60 * 1000, // 10 minutes
    forceReload: false,
};

export default AutoReload;