import React, { useState, useEffect, useRef } from 'react';
import { MirList } from 'shared/settings/apiconfig';
import Datatable from 'shared/datatable';
import { useHistory } from 'react-router-dom'; 

const GD = ({ filter, mirstatus, saleidfilter, saleid, state, props }) => {
    const history = useHistory();
    const initialSearchState = {
        saleid: { field: "saleid", value: saleid, op: "=", join: "AND" },
        type: { field: "type", value: 'DG', op: "=", join: "AND" }
    };

    const datatable = useRef(null);

    const [search, setSearch] = useState(initialSearchState);
    const [updateSearch, setUpdateSearch] = useState(false);
    const [navigateTo, setNavigateTo] = useState(null);
    const [navigateToDG, setNavigateToDG] = useState(null);
    const [tableData, setTableData] = useState(null);

    const customInvoiceView = (values) => {
        return (
            <span style={{ color: "blue", cursor: "pointer" }} onClick={() => invoiceview(values.parentinvoiceid)}>
                {values['parentInvoiceNumber'] ? values['parentInvoiceNumber'] : '- - -'}
            </span>
        );
    };
    const customGDInvoiceView = (value) => {

        return (
            <span>
                {value['invoicenumber'] ? value['invoicenumber'] : '- - -'}
            </span>
        );

    }

    const [config, setConfig] = useState({
        url: MirList,
        params: {},
        columns: [
            { name: 'Item Title', property: 'itemtitle', sort: false, width: '250px' },
            { name: 'Invoice Number', property: 'invoicenumber', sort: false, width: '130px',  columnType: 'custom', customColumn: customGDInvoiceView },
            // { name: 'Parent Invoice', property: 'parentInvoiceNumber', sort: false, width: '130px', columnType: 'custom', customColumn: customInvoiceView },
            { name: 'Email', property: 'buyeremail', sort: false, width: '130px' },
            { name: 'SKU', property: 'SKU', sort: false, width: '130px' },
            { name: 'Contract ID', property: 'contractid', sort: false, width: '130px' },
            { name: 'Contract Status', property: 'contractstatus', sort: false, width: '130px' },
            { name: 'Claim ID', property: 'claimid', sort: false, width: '130px' },
            { name: 'Claim Status', property: 'claimstatus', sort: false, width: '150px' },
        ],
        pagination: true,
        field: ["id","orderid","contractid","contractstatus","claimid","claimstatus","itemtitle","SKU","category","invoicenumber","buyeremail","type", "parentinvoiceid","invoiceid"],
        ignoreRowBackground: true
    });

    useEffect(() => {
        const mergedFilter = { ...initialSearchState, ...filter };
        setSearch(mergedFilter);
        setUpdateSearch(true);
    }, [filter]);

    useEffect(() => {
        if (updateSearch) {
            if (datatable.current) {
                datatable.current.searchData(props?.location?.state?.datatable?.next ? 1 : null);
                setUpdateSearch(false);
            }
        }
    }, [search]);

    useEffect(() => {
        if (navigateToDG) {
            history.push({
                pathname: `/invoice/dg/view/${navigateToDG}`,
                state: {
                    dgStatus : true,
                    prevPath: history?.location?.pathname,
                    state: state,
                    datatable: tableData.stateData,
                }
            });
            setNavigateToDG(null);
        }
    }, [navigateToDG]);

    useEffect(() => {
        if (navigateTo) {
            history.push({
                pathname: `/invoice/view/${navigateTo}`,
                state: {
                    mirstatus: true,
                    prevPath: history?.location?.pathname,
                    state: state,
                    datatable: tableData.stateData,
                }
            });
            setNavigateTo(null);
        }
    }, [navigateTo]);

    const invoiceview = (parentinvoiceid) => {
        setNavigateTo(parentinvoiceid);
    };
    const invoiceDGview = (invoiceid) => {
        setNavigateToDG(invoiceid);
    };

    const updateTableData = (event) => {
        setTableData(event);
    };

    return (
        <div className="" style={{ width: '100%' }}>
            <Datatable {...props} config={{ ...config }} search={{ search: { ...search }, searchData: updateSearch }} ref={datatable} onDataUpdate={updateTableData} />
        </div>
    );
};

export default GD;
